import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`faulty.cloud`, `blog`, `infosec`, `security`]}
    />
    <h1>Welcome to the faulty.cloud!</h1>
    <p>
      This is merely a private website / blog where i collect my thoughts,
      ideas, projects and some docs.
    </p>
    <p>
      Check out my spare time projects in the{" "}
      <Link to="/projects/">Projects Pages</Link>
    </p>

    <h2>Latest stuff</h2>
    {data.allMarkdownRemark.edges.map((post) => (
      <div key={post.node.id}>
        <h3>
          <Link to={post.node.frontmatter.path}>
            {post.node.frontmatter.title}
          </Link>
        </h3>
        <small>
          <i className="far fa-calendar" /> {post.node.frontmatter.date}
        </small>
        <p>{post.node.frontmatter.excerpt}</p>
        <hr />
      </div>
    ))}
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query LatestBlogPostsQuery {
    allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            author
            excerpt
          }
        }
      }
    }
  }
`
